






















































































import { Component } from "vue-property-decorator";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { loadWidget } from "@/utils/helpers";
import { mixins } from "vue-class-component";
import WidgetMixins from "@/mixins/WidgetMixins";

extend("required", {
  ...required,
  message: "Field is required"
});

@Component({
  name: "ResetPassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: loadWidget("widgets/SnackBar")
  }
})
export default class ResetPassword extends mixins(WidgetMixins) {
  newPassword: string | null = null;
  confirmPassword: string | null = null;

  fieldState: any = {
    newPassword: false,
    confirmPassword: false
  };

  resetPassword() {
    let payload = {
      token: this.$route.query.token,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword
    };

    this.$store.dispatch("auth/resetPassword", payload);
  }

  changeState(state: string) {
    this.$set(this.fieldState, state, !this.fieldState[state]);
  }
}
